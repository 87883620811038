import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Footer from '../footer'
import Modal from '../modal'

import '../../scss/main.scss'

class Layout extends Component {
  componentDidMount = () => {
    this.props.checkUrlSrc(this.props?.location?.search)
    this.props.checkUrlCampaign(this.props?.location?.search)
    this.props.checkPrePopulatedData(this.props?.location?.search)
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.captive.campaign !== prevProps.captive.campaign) {
      if (window.dataLayer) {
        window.dataLayer.push({
          utm_campaign: this.props.captive.campaign,
        })
      }
    }
    if (this.props.captive.src !== prevProps.captive.src) {
      if (window.dataLayer) {
        window.dataLayer.push({
          src: this.props.captive.src,
        })
      }
    }
  }

  render() {
    const { children, phone, hideFooter } = this.props
    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={(data) => (
          <div id="wrapper">
            <Helmet key="ipqualityscore">
              <script
                src="https://www.ipqualityscore.com/api/lifeinsurancecompanion.com/RQwsSGMrd34kgx9VKfo3ZHbEh2zRde02zE5BBiOKtminmXnoKc5ajS7unPf1vVabbT7gayM5QBfHLnG8dNcpkJEOxXw5DXNue73XbTnXaWQ7IuVdeBUbaZWVaCl94O9MQJqGMSY2kD441DYg8Jb3PRwbMI4j3BkvAeaqMKyNGKCxj5prlI3w6HupxbcnwDnH17545ip6wreCe4oNtZSJnOznw9V3i7u4IXgCxsc2X3CWLI67mteAjgvbPt135nmP/learn.js"
                crossorigin="anonymous"
              ></script>
              <noscript>
                {`<img
                  src="https://www.ipqualityscore.com/api/lifeinsurancecompanion.com/RQwsSGMrd34kgx9VKfo3ZHbEh2zRde02zE5BBiOKtminmXnoKc5ajS7unPf1vVabbT7gayM5QBfHLnG8dNcpkJEOxXw5DXNue73XbTnXaWQ7IuVdeBUbaZWVaCl94O9MQJqGMSY2kD441DYg8Jb3PRwbMI4j3BkvAeaqMKyNGKCxj5prlI3w6HupxbcnwDnH17545ip6wreCe4oNtZSJnOznw9V3i7u4IXgCxsc2X3CWLI67mteAjgvbPt135nmP/pixel.png"
                  alt=""
                />`}
              </noscript>
              <script>
                {`
if(typeof Startup !== "undefined") {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  // Startup.Store('transactionID', '');
  // Startup.Store('userID', '');
  // Startup.Store('subUserID', '');
  // Startup.Store('campaignID', '');
  // Startup.Store('subCampaignID', '');
  // Startup.Store('publisherID', '');
  // Startup.Store('gclid', '');
  // Startup.Store('clickID', '');
  // Startup.Store('conversionID', '');
  Startup.Store('Sub_ID', urlParams.get('Sub_ID'));
  Startup.Store('Src_ID', urlParams.get('Src_ID'));
  Startup.Store('SRC', urlParams.get('SRC'));
  Startup.Store('Pub_ID', urlParams.get('Pub_ID'));
  Startup.Store('phone', urlParams.get('phone'));

} 

if(typeof Startup !== "undefined") {
  Startup.AfterResult(function(result){
    // redirect or perform business logic if Fraud Score is >= recommended value of 90
    if(result.fraud_chance >= 90) {
      // window.location.href = "https://www.google.com"; 
    }
  });
  
  Startup.AfterFailure(function(reason){
    // user has blocked the second JavaScript call
    // can redirect or perform other business logic if JS is not loaded
    // window.location.href = "https://www.google.com";
  });
}

if(typeof Startup === "undefined") {
  // user has blocked the initial JavaScript call
  // can redirect or perform other business logic if JS is not loaded
  // window.location.href = "https://www.google.com";
}
`}
              </script>
            </Helmet>
            <a className="accessibility" href="#main">
              Skip to Content
            </a>
            {children}
            {!hideFooter && <Footer {...this.props} />}
            <a className="accessibility" href="#wrapper">
              Back to top
            </a>
            <Modal type="terms" />
            <Modal type="privacy" />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  phone: PropTypes.string,
  hideFooter: PropTypes.bool,
  hideCta: PropTypes.bool,
}

Layout.defaultProps = {
  phone: ``,
  hideFooter: false,
  hideCta: false,
}

export default Layout
